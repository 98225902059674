export const lang = {
    //公共
    document_title:'稿件学术诚信风险自查系统',
    confirm:'确定',
    confirm_pay:'捐赠',
    key_point: "维护科研诚信，杜绝学术不端",
    no_data:'暂无数据',
    unknown:'未知',
    timeout_dialog_title:'温馨提示',
    timeout_dialog_conetent_html:'检测超时！请联系工作人员解决：',

    btn_cancer:'取消',
    btn_update:'修改',
    btn_upgrade:'马上升级',

    global_tip_title:'温馨提示',
    global_tip_confirm_known:'知道了',
    global_tip_confirm_ok:'好的',
    global_tip_success:'成功',
    global_tip_check_num_zero:'检测次数已用完，现在升级权限。',

    home_checkout: '请先扫码登录微信，每月可检测{1}次，谢谢。',
    contact:"联系我们",

    //顶部
    header_lang: "English",
    // figcheck_header_login: "邮箱登录",
    header_login: "微信登录",
    header_login_prompt: "每月{0}次检测机会,请合理使用",
    header_quit: "退出",
    header_ret_home: "点击返回首页",
    header_click_copy_user_nickname: "点击复制用户昵称",

    header_menu_api_access:"API接入",
    header_menu_down_word_plugin:"下载Word插件",

    header_user_id:"用户ID",
    header_username:"用户名",
    header_user_nick_name:"用户昵称",
    header_user_remain_num:"剩余次数",
    header_user_pay:"捐赠",
    header_user_click_copy_userID:"点击复制用户ID",
    header_user_click_update_nickName:"点击修改昵称",
    header_user_update_nickName:"修改昵称",
    header_user_input_new_nickName:"请输入新昵称",
    header_copy_success:"复制成功",
    loginbox_msg_logout_succ:'注销成功',

    //首页
    upload_box_drag_here:"拖拽文件至此处，或点击此处",
    upload_box_upload:"上传",
    upload_box_upload_support_format:"支持格式: docx",
    checking:"检查中...",
    start_check:"开始运行",
    check_time_prefix:"大约",
    check_time_postfix:"秒后完成检查",
    check_waiting:"当前查询人数较多,正在排队中,请耐心等候...",
    upload_box_msg_size:"单个docx文献请不要超过{0}MB，谢谢。",
    upload_box_msg_not_docx:"拖入的不是docx稿件",
    check_limit_prompt:"温馨提示：本系统适配示例的原始投稿排版，其它排版可能导致识别遗漏。",


    check_option_title:"检测内容",
    check_option_ref_sim:"参考文献相似分析",
    check_option_org:"识别机构",
    check_option_author_article:"作者发文情况",
    check_option_year_stat:"参考文献年份统计",
    check_option_suspect_ref:"参考文献可疑不当引用",
    check_option_ref_risk:"参考文献学术诚信风险",
    check_option_author_risk:"作者学术诚信风险预警",
    check_option_email_risk:"通讯作者邮箱学术诚信预警",
    check_option_email_sim:"通讯作者邮箱相似风险预警",
    check_option_figcheck:"图片查重",

    check_result_down_report:"点击下载报告",
    check_result_msg_no_report:"尚无报告可下载",

    check_result_ref_sim_title:"参考文献相似度检查",
    check_result_ref_sim_prompt:"参考文献中出现的相似的文献,相似度超过80%则给予预警",
    check_result_sim_prompt:"以下文献疑似重复",
    check_result_sim_percent:"相似率",
    check_result_ref_sim_zero_result_prompt:"恭喜，参考文献中未出现重复",

    check_result_org_title:"作者机构",
    check_result_no:"序号",
    check_result_org_name:"机构名",
    check_result_org_zero_result_prompt:"当前稿件未发现有作者机构",

    check_result_author_article_title:"作者文献发表情况",
    check_result_author:"作者",
    check_result_org:"机构",
    check_result_pub_article_count:"发表文献数",
    check_result_max_if:"最高IF",
    check_result_avg_if:"平均IF",
    check_result_author_article:"相关文章",
    check_result_view:"查看",
    check_result_author_aritcle_zero_result_prompt:"通讯作者未发表过其他文献",

    check_result_ref_year_stat_title:"参考文献年份统计",
    check_result_year:"年份",
    check_result_cur_year_num:"当年条数",
    check_result_count_num:"累计条数",
    check_result_cur_year_percent:"当年占比",
    check_result_count_percent:"累计占比",
    check_result_ref_zero_result_prompt:"恭喜，没有发现涉及学术诚信的参考文献。",

    check_result_ref_detail_stat_title:"参考文献详情统计（包括学术诚信风险）",
    check_result_ref_detail_stat_prompt:"参考文献中出现了学术诚信风险预警",
    check_result_fullscreen_view:"查看全部",
    check_result_figcheck_readme:"结果判读说明",
    check_result_journal:"期刊",
    check_result_ifs:"影响因子",
    check_result_pub_year:"发表年份",
    check_result_warning:"诚信预警",
    check_result_click_view:"点击查看",

    check_result_ref_risk_title:"参考文献可疑不当引用",
    check_result_ref_risk_prompt:"如本文所有参考文献中，同一作者文章出现>=3次，则给于预警。红色为本文作者之一，提示可疑过度自引。",
    check_result_show_num:"出现次数",
    check_result_ref_no:"引文序号",
    check_result_ref_risk_zero_result_prompt:"恭喜，参考文献中未出现可疑不当引用",

    check_result_author_risk_title:"作者学术诚信风险预警",
    check_result_author_risk_prompt:"如本文作者其他文章有涉及学术诚信风险，则给于预警",
    check_result_article:"文献",
    check_result_link:"链接",
    check_result_author_risk_zero_result_prompt:"恭喜，当前稿件未发现作者涉及其他学术诚信风险",

    check_result_email_risk_title:"通讯作者邮箱学术诚信预警",
    check_result_email_risk_prompt:"系统自动匹配相同涉及学术诚信问题的邮箱",
    check_result_email:"邮箱",
    check_result_pub_date:"发布日期",
    check_result_warning_link:"预警链接",
    check_result_email_risk_zero_result_prompt:"恭喜，稿件中未出现涉及学术诚信的通讯邮箱。",

    check_result_email_sim_title:"通讯作者邮箱相似风险预警",
    check_result_email_sim_prompt:"论文工厂通常使用相近邮箱进行注册，本项中，系统自动匹配相同或相近的邮箱，仅供参考",
    check_result_email_sim_zero_result_prompt:"恭喜，稿件中未出现有相似风险的通讯邮箱。",

    check_result_title_figcheck:"Figcheck查重",

    check_result_dialog_title_postfix_year_pub:"的年度发表量",
    check_result_dialog_title_postfix_ifs:"的影响因子分布图",
    check_result_dialog_title_postfix_pub_article:"的发表文献",
    check_result_pub_count:"发表量",



    //上传部分
    figcheck_upload_prompt_prefix:'将Figure拖动到此处, 或者',
    figcheck_upload_prompt_click:'点击',
    figcheck_upload_prompt_postfix:'上传',
    figcheck_upload_small_prompt:'支持格式: jpg, png, tif, pdf, docx',
    figcheck_upload_title:'已上传的图片: ',
    figcheck_upload_start_check:'开始检查',
    figcheck_upload_start_checking:'检查中...',
    figcheck_upload_reupload:'重新上传',
    figcheck_upload_time_predict:'大约 {0} 秒后完成检查',
    figcheck_upload_split_wait:'当前查询人数较多,正在排队中,请耐心等候...',
    figcheck_upload_limit_pdf:'PDF格式仅支持文献，最多1个；多张图片请以非PDF格式上传，谢谢。',
    figcheck_upload_error_pdf:'PDF文件格式有误,请换一个上传,谢谢',
    figcheck_upload_limit_pdf_page:'您的PDF文件已超过限定页数（{0}页）请酌情删减，谢谢。',
    figcheck_upload_limit_pdf_org:'机构用户PDF格式文献最多支持50个，谢谢。',
    figcheck_upload_limit_size_pdf:'单个pdf文献请不要超过{0}MB，谢谢。',
    figcheck_upload_limit_size_image:'单个图片请不要超过{0}MB，谢谢。',
    figcheck_upload_limit_size_total:'上传的图片或pdf文献总大小请不要超过{0}MB，谢谢。',
    figcheck_upload_limit_format:'拖入的不是图片(png/jpg/tif)、docx、pdf文献',
    figcheck_upload_limit_num:'检测超时，请尝试减少图片清晰度，减少上传图片数等',
    // figcheck_upload_limit_gt_count:'检测的图片过多,请缩减图片后再上传检测,检测到小图数量: {0} 张 检查最大小图限制: {1}张',
    figcheck_upload_limit_gt_count:'检测图片过多，或过于高清，请尝试删减图片或降低图片像素后重试，降低清晰度不影响检测灵敏度。',
    figcheck_upload_limit_not_gt_10:'一次检索，请不超过10个文件，谢谢。',
    figcheck_upload_limit_gt_3_100s:'温馨提示：你的账户累计{0}次查重时间大于{1}s，账户已自动锁定，如需继续使用，请联系我们，谢谢。',
    //结果部分
    figcheck_result_source_title:'可能相似的图片对: ',
    figcheck_result_source_title_no_result:'没有发现相似图片。',
    figcheck_result_ps_source_title:'可能存在ps痕迹的原图: ',
    figcheck_result_ps_source_title_no_result:'没有检测到有ps痕迹的图片。',
    figcheck_result_detail_title:'细节对比: ',
    figcheck_result_ps_detail_title:'ps痕迹预测: ',
    figcheck_result_detail_option_show_line:'显示线',
    figcheck_result_detail_option_show_line_intro:'红线连接两张图片之间相似的特征值，帮助指示特征值之间相似的方式，如复制、旋转、反转等。',
    figcheck_result_detail_option_remove_label:'去标注',
    figcheck_result_detail_option_remove_label_intro:'图片边缘的标注部分（如图示等）将不会纳入特征值比较，去除图片标注导致的假阳性干扰。',
    figcheck_result_detail_option_ps:'PS痕迹',
    figcheck_result_detail_option_ps_intro:'对文件中的WB图进行单独识别，类型包含针对条带的“复制，去除”2种图片操纵，经内部1000个样本测试，结果的真阳性率约为92.3%。',
    figcheck_result_single_ps_pred_prefix: '疑似',
    figcheck_result_single_ps_pred_text_add: '复制',
    figcheck_result_single_ps_pred_text_remove: '去除',
    // 提示：人工智能算法大大提高了重复图片的识别效率，但上图红线所示重复区域不代表一定是有意义的重复，比如图标，merge的荧光图等；一般，红线越聚集，重复的可能性越大。请根据实际情况判断。
    figcheck_result_ps_no_wb:'结果中无wb图,无法使用ps查询。',
    figcheck_result_detail_prompt:'提示：人工智能算法大大提高了重复图片的识别效率，但上图红线所示重复区域不代表一定是有意义的重复，比如图标，merge的荧光图等；一般，红线越聚集，重复的可能性越大。请根据实际情况判断。',
    figcheck_result_ps_detail_prompt:'PS痕迹检测功能经内部1000个样本测试，结果的真阳性率约为92.3%，即以上高亮区域有92.3%可能是进行了右侧所示的图片操纵类型。但仍有7.7%的假阳性，请根据实际情况判断。',
    figcheck_result_down_pdf:'下载报告',
    figcheck_result_downing_pdf:'生成中...',
    //pdf报告
    figcheck_result_pdf_filename:'图片查重报告',
    figcheck_result_pdf_processing:'图片查重处理中,稍等片刻再下报告',
    //案例及友情链接
    figcheck_case_title:'图片重复案例',
    figcheck_links_title:'友情链接',
    figcheck_links_link_3:'国家卫健委',
    figcheck_links_link_4:'国务院',
    figcheck_links_link_5:'中华医学会',
    figcheck_links_link_6:'国自然基金委',
    //新增 9月18号


    //登录注册
    //公共
    figcheck_loginbox_input_email:'请输入邮箱',
    figcheck_loginbox_input_pass:'请输入密码',
    figcheck_loginbox_link_login_wechat:'微信登录',
    figcheck_loginbox_link_reg:'点击注册',
    figcheck_loginbox_link_find_pass:'找回密码',
    figcheck_loginbox_link_login:'点击登录',
    //登录
    figcheck_loginbox_login_title:'登录',
    figcheck_loginbox_login:'登录',
    //注册
    figcheck_loginbox_reg_title:'邮箱注册',
    figcheck_loginbox_send_code:'发送验证码',
    figcheck_loginbox_input_code:'请输入邮箱验证码',
    figcheck_loginbox_reg:'注册',
    //找回密码
    figcheck_loginbox_reset_title:'找回密码',
    figcheck_loginbox_input_new_pass:'请输入新密码',
    // figcheck_loginbox_confirm:'确认',
    //错误提示
    figcheck_loginbox_err_email:'输入的邮箱格式不正确,请重新输入',
    figcheck_loginbox_err_pass:'密码不合法,密码为6位以上数字字母组合',
    figcheck_loginbox_err_code:'邮箱验证码不合法,验证码为4位字母或数字',
    //消息提示
    figcheck_loginbox_msg_login_succ:'登录成功',
    figcheck_loginbox_msg_login_fail:'登录失败,获取用户信息失败,请重试',
    figcheck_loginbox_msg_send_limit:'发送邮件间隔限制,请稍等一会',
    figcheck_loginbox_msg_reg_succ:'新用户邮箱注册成功,可以通过邮箱和密码进行登录',
    figcheck_loginbox_msg_reset_succ:'密码重置成功,自动登录成功',
    figcheck_loginbox_msg_reset_succ_login_fail:'密码重置成功,自动登录失败,请尝试手动登录',
    figcheck_loginbox_msg_reset_fail:'密码重置失败,请重试',
    figcheck_loginbox_msg_send_email_fail:'发送邮件失败',
    figcheck_loginbox_msg_send_email_succ:'发送邮件成功',
    figcheck_loginbox_msg_code_err:'验证码错误,错误次数已达{0}次,还能重试次数{1}次',
    figcheck_loginbox_msg_code_err_no_num:'验证码错误',
    figcheck_loginbox_msg_login_gt_limit:'登录错误超过限制,请过会再试',
    figcheck_loginbox_msg_login_user_in_black_list:'你的账号已被锁定,暂时无法登录使用',
    figcheck_loginbox_msg_login_ip_in_black_list:'你的IP已被锁定,暂时无法登录使用',
    figcheck_loginbox_msg_user_pass_err:'账号或密码错误,还能重试{0}次',
    figcheck_loginbox_msg_user_not_exist:'用户可能不存在,请先注册',
    figcheck_loginbox_msg_user_has_exist:'用户已存在,请登陆',
    figcheck_loginbox_msg_reg_fail:'账号注册失败,请重新注册',
    figcheck_loginbox_msg_logout_succ:'注销成功',
    //邮箱验证码
    figcheck_email_titile:'邮箱验证码 by Figcheck',
    figcheck_email_content:'你好，请将以下验证码 0000 输入至注册页面输入框，完成注册，谢谢。',
    //头部菜单
    figcheck_menu_self_check:'学术自查',
    figcheck_menu_readme:'使用说明',
    figcheck_menu_pub_sub:'Pubpeer订阅',
    figcheck_menu_peer_review:'同行评议',
    figcheck_menu_api_access:'机构',

    //付费页
    figcheck_pay_intro:"Figcheck是国内首个开放式图像查重平台，自2021年9月份上线以来，即受到了广泛关注，截止2022年5月30日，已运行查重超过30万次。由于图像识别算法需使用GPU服务器（运算成本较高），为了平台能够持续迭代和维护，Figcheck即日起开始接收捐款。",
    figcheck_pay_id_account:"ID账号",
    figcheck_pay_remain_num:"剩余次数",
    figcheck_pay_remain_num_unit:"次",
    figcheck_pay_not_login:"还未登录",
    figcheck_pay_action:"捐款",
    figcheck_pay_money_unit_char:"￥",
    figcheck_pay_money_unit_str:"元",
    figcheck_pay_order_num:"订单编号",
    figcheck_pay_type:"支付方式",
    figcheck_pay_type_wx:"微信扫码",
    figcheck_pay_type_ali:"支付宝扫码",
    figcheck_pay_msg:"消息",
    // figcheck_pay_prompt_1:"1. 超时不计入次数。",
    figcheck_pay_prompt_2:"1.本支付为特殊虚拟商品，不支持退款，请捐款前充分确认是否有用，谢谢。",
    figcheck_pay_prompt_3:"2.请勿重复支付;支付完成后,若次数未增加,请退出并重新登录;其他问题请及时联系客服。",
    figcheck_pay_prompt_4_1:"3.发票开具请联系",
    figcheck_pay_prompt_4_2:"工作人员",
    figcheck_pay_prompt_4_3:"。",
    figcheck_pay_qcode_title:"扫码加客服微信",
    figcheck_pay_qcode_prompt:"客服微信",

    figcheck_pay_msg_success:'支付完成',
    figcheck_pay_msg_nopay:'未支付',
    // mcarcheck

    check_result_toggle_to_list_view:"查看列表",
    check_result_toggle_to_chart_view:"查看柱状图",


    article_pub_date:"发布日期",
    article_article_type:"文献类型",
    article_cited_num:"被引数",
    article_keyword:"关键词",
    article_author:"作者",
    article_click_middle_author:"点击展开中间的作者",
    article_journal:"期刊",
    article_unpublish:"未公布",
    article_cas:"中科院",
    article_cas_postfix:"区",
    article_warning:"预警",
    article_fisrt_collect:"首次收录",
    article_collect_time:"收藏时间",
    article_summary:"摘要",
    article_expand_summary:"展开摘要",
    article_contraction_summary:"收缩摘要",
    article_zh_summary:"中文摘要",
    article_en_summary:"英文摘要",
    article_summary_translating:"摘要翻译中...",
    article_click_retranslate:"翻译不对？点击重新翻译",
    article_no_summary:"无摘要",
    article_reason:"原因",
}
