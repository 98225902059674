export const lang = {
    //公共
    document_title:'Manuscript Academic Integrity Risk Assessment System',
    confirm:'Confirm',
    confirm_pay:'Confirm',
    key_point: "FigCheck is free!",
    no_data:'no data',
    unknown:'Unknown',
    timeout_dialog_title:'Tips',
    timeout_dialog_conetent_html:'Timeout! Contact admin@figcheck.com for help.',

    btn_cancer:'Cancer',
    btn_update:'Update',
    btn_upgrade:'Upgrade',

    global_tip_title:'Tips',
    global_tip_confirm_known:'Noticed',
    global_tip_confirm_ok:'ok',
    global_tip_success:'Success',
    global_tip_check_num_zero:'Free times are running out! Upgrade your permission!',

    home_checkout: 'Please register with your email address to keep using Mcar {1} times per month.',
    contact:"Contact Us",

    //顶部
    header_lang: "中文",
    header_login: "Login",
    header_login_prompt: "Login first for better function use",
    header_quit: "Logout",
    header_ret_home: "Click to back home",
    header_click_copy_user_nickname: "Click to copy user nickname",

    header_menu_api_access:"API Access",
    header_menu_down_word_plugin:"Download Word Plugin",

    header_user_id:"User ID",
    header_username:"Username",
    header_user_nick_name:"User’s Nickname",
    header_user_remain_num:"Remaining Times",
    header_user_pay:"Donate",
    header_user_click_copy_userID:"Click to copy User ID",
    header_user_click_update_nickName:"Click to change User’s Nickname",
    header_user_update_nickName:"Change User’s Nickname",
    header_user_input_new_nickName:"New Nickname",
    header_copy_success:"Copied Successful",
    loginbox_msg_logout_succ:'Logout Successful',

    //首页
    upload_box_drag_here:"Drag files here or Click to",
    upload_box_upload:"upload",
    upload_box_upload_support_format:"File Format: docx",
    checking:"Checking...",
    start_check:"Start running",
    check_time_prefix:"The check will complete in about",
    check_time_postfix:"seconds",
    check_waiting:"A lot of inquires currently! Queuing, please wait patiently!",
    upload_box_msg_size:"Please upload a docx file with a size no more than {0}MB.",
    upload_box_msg_not_docx:"Only docx file is allowed.",
    check_limit_prompt:"Kind reminder: This system is adapted to the original post layout of the sample. Other layouts may cause recognition omissions. ",

    check_option_title:"Testing Contents",
    check_option_ref_sim:"Duplicate references",
    check_option_org:"Institution identified",
    check_option_author_article:"Author information",
    check_option_year_stat:"Reference year of publication",
    check_option_suspect_ref:"Suspicious references",
    check_option_ref_risk:"Academic integrity risk of references",
    check_option_author_risk:"Academic integrity risk of authors",
    check_option_email_risk:"Email address of the corresponding author",
    check_option_email_sim:"Alert for similar Email addresses of corresponding authors",
    check_option_figcheck:"FigCheck image duplication detection",

    check_result_down_report:"Download your report",
    check_result_msg_no_report:"No report available",

    check_result_ref_sim_title:"Duplicate references",
    check_result_ref_sim_prompt:"We will push alert if the similarity between the references in the list exceeds 80%.",
    check_result_sim_prompt:"Suspicious duplicates",
    check_result_sim_percent:"Similarity",
    check_result_ref_sim_zero_result_prompt:"Congratulate! No duplicates are found.",

    check_result_org_title:"Author institution",
    check_result_no:"Order",
    check_result_org_name:"Institution name",
    check_result_org_zero_result_prompt:"No author institution found in the manuscript.",

    check_result_author_article_title:"Author information",
    check_result_author:"Authors",
    check_result_org:"Institution",
    check_result_pub_article_count:"Number of papers published",
    check_result_max_if:"Max IF",
    check_result_avg_if:"Avg IF",
    check_result_author_article:"Related literatures",
    check_result_view:"View",
    check_result_author_aritcle_zero_result_prompt:"No other literatures published by the corresponding author.",

    check_result_ref_year_stat_title:"Reference year of publication",
    check_result_year:"Year of publication",
    check_result_cur_year_num:"Reference number of the year",
    check_result_count_num:"Cumulative number",
    check_result_cur_year_percent:"Proportion of the year",
    check_result_count_percent:"Cumulative proportion",
    check_result_ref_zero_result_prompt:"Congratulations, no references regarding academic integrity were found.",

    check_result_ref_detail_stat_title:"Reference details (including academic integrity risk)",
    check_result_ref_detail_stat_prompt:"Academic integrity issues found in references",
    check_result_fullscreen_view:"Full-screen",
    check_result_figcheck_readme:"Explanation of Results",
    check_result_journal:"Journal",
    check_result_ifs:"IF",
    check_result_pub_year:"Year of publication",
    check_result_warning:"Academic integrity risk",
    check_result_click_view:"More details",

    check_result_ref_risk_title:"Suspicious references",
    check_result_ref_risk_prompt:"We will push alert when the frequency of one author is up to or over 3.The red font indicates the author of this article, indicating suspicious excessive self citation.",
    check_result_show_num:"Frequency",
    check_result_ref_no:"Citation number",
    check_result_ref_risk_zero_result_prompt:"Congratulate! No suspicious references are found!",

    check_result_author_risk_title:"Academic integrity risk of author",
    check_result_author_risk_prompt:"We will push alert if there is an academic integrity risk involved in other papers of the authors of the manuscript",
    check_result_article:"Paper",
    check_result_link:"Link",
    check_result_author_risk_zero_result_prompt:"Congratulate! None of the authors of the manuscript are involved in academic integrity risk.",

    check_result_email_risk_title:"Email address of the corresponding author",
    check_result_email_risk_prompt:"The system is automatically checking other Emails with academic integrity issues",
    check_result_email:"Email address",
    check_result_pub_date:"Year of publication",
    check_result_warning_link:"Link",
    check_result_email_risk_zero_result_prompt:"Congratulate! There is no risk involved in the email address of the corresponding author. ",

    check_result_email_sim_title:"Alert for similar Email addresses of corresponding authors",
    check_result_email_sim_prompt:"This system automatically matches identical or similar email addresses that are involved in academic integrity issues, such as the similar email addresses used by paper mills, and the testing result is only for reference",
    check_result_email_sim_zero_result_prompt:"Congratulate! No any other Emails of corresponding authors found with academic integrity issues",

    check_result_title_figcheck:"FigCheck image duplication detection",

    check_result_dialog_title_postfix_year_pub:"Annual Publications",
    check_result_dialog_title_postfix_ifs:"IF Distribution",
    check_result_dialog_title_postfix_pub_article:"Publications",
    check_result_pub_count:"Number of Publications",

    //上传部分
    figcheck_upload_prompt_prefix:'Drag files here or ',
    figcheck_upload_prompt_click:'Click',
    figcheck_upload_prompt_postfix:' to upload',
    figcheck_upload_small_prompt:'File Formats: jpg, png, tiff, pdf, docx',
    figcheck_upload_title:'Uploaded:',
    figcheck_upload_start_check:'Processing',
    figcheck_upload_start_checking:'Processing...',
    figcheck_upload_reupload:'Re-upload',
    figcheck_upload_split_wait:'At present, there are a large number of inquiries. You are waiting in line. Please wait patiently.',
    figcheck_upload_time_predict:'The check will be completed in around {0} seconds',
    figcheck_upload_limit_pdf:'PDF format support for article submission only with a maximum of one file at a time. Multiple figures must be uploaded in other formats (jpg, png, Tif).',
    figcheck_upload_limit_pdf_page:' Your PDF file has exceeded the limit ({0} pages), please reduce it as appropriate, thank you.',
    figcheck_upload_error_pdf:'The PDF file format is incorrect. Please upload another one. Thank you.',
    figcheck_upload_limit_pdf_org:'The maximum number of documents in PDF format for institutional users is 50, thank you.',
    figcheck_upload_limit_size:'Please do not exceed {0}MB per picture or pdf document. Thank you.',
    figcheck_upload_limit_size_pdf:'Please do not exceed {0}MB per pdf document. Thank you.',
    figcheck_upload_limit_size_image:'Please do not exceed {0}MB per picture. Thank you.',
    figcheck_upload_limit_size_total:'Please do not exceed {0}MB total picture or pdf document. Thank you.',
    figcheck_upload_limit_format:'Incorrect picture format (jpg, png, tiff), docx , PDF file.',
    figcheck_upload_limit_num:'Too many pictures uploaded. Please re-upload with fewer pictures.',
    figcheck_upload_limit_gt_count:'There are too many detected pictures, or too high-definition, please try to delete the pictures or reduce the picture pixels and try again. Decreasing the resolution will not affect the detection sensitivity.',
    figcheck_upload_limit_not_gt_10:'Please do not exceed 10 files per search. Thank you.',
    figcheck_upload_limit_gt_3_100s:'Your account has been checked for more than {1}s for {0} times. If you want to continue to use it, please contact us, thank you.',
    //结果部分
    figcheck_result_source_title:'Similar sources:',
    figcheck_result_source_title_no_result:'No matches',
    figcheck_result_ps_source_title:'Original image:',
    figcheck_result_ps_source_title_no_result:'No pictures with traces of ps were detected.',
    figcheck_result_detail_title:'Details:',
    figcheck_result_ps_detail_title:'Ps trace prediction: ',
    figcheck_result_detail_option_show_line:'Markers',
    figcheck_result_detail_option_show_line_intro:'Red lines connect similar characteristics between two images to indicate their correlation, such as copy, rotation, inversion, etc.',
    figcheck_result_detail_option_remove_label:'Clear vision',
    figcheck_result_detail_option_remove_label_intro:'Annotations around the picture (such as lettering, scales etc.) will not be included, removing the false positive interference.',
    figcheck_result_detail_option_ps:'PS traces',
    figcheck_result_detail_option_ps_intro:'The WB image in the file will be individually identified, and the type includes two image manipulations of "copy, removal". After an internal test of 1,000 samples, the true positive rate of the results is about 92.3%. ',
    figcheck_result_single_ps_pred_prefix: 'Suspected ',
    figcheck_result_single_ps_pred_text_add: 'replication',
    figcheck_result_single_ps_pred_text_remove: 'removal',
    figcheck_result_ps_no_wb:'There is no wb diagram in the result, so you cannot use ps query. ',
    figcheck_result_detail_prompt:'Tip: Although artificial intelligence algorithm greatly improves the recognition efficiency for duplicate pictures, the repeated part marked in red lines may not be always meaningful, such as the icons and the fluorescence images (merge). Generally, the more convergence the red lines, the greater the possibility of repetition. Please consider the actual situation. ',
    figcheck_result_ps_detail_prompt:'The PS trace detection function has been tested on 1000 internal samples, and the true positive rate of the results is about 92.3%, that is, 92.3% of the highlighted areas above may be the type of image manipulation shown on the right. But there are still 7.7% false positives, please judge according to the actual situation. ',
    figcheck_result_down_pdf:'Download Report',
    figcheck_result_downing_pdf:'Preparing...',
    //pdf报告
    figcheck_result_pdf_filename:'FigCheck Originality Report',
    figcheck_result_pdf_processing:'Processing. Try again later',

    figcheck_case_title:'Examples of image plagiarism',
    figcheck_links_title:'Links',
    figcheck_links_link_3:'国家卫健委',
    figcheck_links_link_4:'国务院',
    figcheck_links_link_5:'中华医学会',
    figcheck_links_link_6:'国自然基金委',


    //登录注册
    //公共
    figcheck_loginbox_input_email:'Email address',
    figcheck_loginbox_input_pass:'Password',
    figcheck_loginbox_link_login_wechat:'微信登录',
    figcheck_loginbox_link_reg:'Sign up',
    figcheck_loginbox_link_find_pass:'Forgot password?',
    figcheck_loginbox_link_login:'Log in',
    //登录
    figcheck_loginbox_login_title:'Log in',
    figcheck_loginbox_login:'Log in',
    //注册
    figcheck_loginbox_reg_title:'Sign up by Email',
    figcheck_loginbox_send_code:'Get code',
    figcheck_loginbox_input_code:'Security code',
    figcheck_loginbox_reg:'Sign up',
    //找回密码
    figcheck_loginbox_reset_title:'Reset password',
    figcheck_loginbox_input_new_pass:'New password',
    //错误提示
    figcheck_loginbox_err_email:'Invalid Email address! Please try again.',
    figcheck_loginbox_err_pass:'Invalid password! Your password must contain at least 6 characters.',
    figcheck_loginbox_err_code:'Invalid code. The security code must contain 4 characters.',
    //消息提示
    figcheck_loginbox_msg_login_succ:'Login successful',
    figcheck_loginbox_msg_login_fail:'Login error. Try again.',
    figcheck_loginbox_msg_send_limit:'Too many operations. Try again later.',
    figcheck_loginbox_msg_reg_succ:'Successful registration. You may log in using your email address and password.',
    figcheck_loginbox_msg_reset_succ:'Password reset successful.',
    figcheck_loginbox_msg_reset_succ_login_fail:'Password reset successfully.  Auto-login failed, please try again manually.',
    figcheck_loginbox_msg_reset_fail:'Password reset failed.',
    figcheck_loginbox_msg_send_email_fail:'Sending failed.',
    figcheck_loginbox_msg_send_email_succ:'Sent.',
    figcheck_loginbox_msg_code_err:'Invalid security code. Four failed attempts, one attempt left.',
    figcheck_loginbox_msg_code_err_no_num:'Incorrect security code.',
    figcheck_loginbox_msg_login_gt_limit:'You have exceeded the maximum number of attempts for this action. Try again later.',
    figcheck_loginbox_msg_login_user_in_black_list:'Your account has been locked.',
    figcheck_loginbox_msg_login_ip_in_black_list:'Your IP has been locked.',
    figcheck_loginbox_msg_user_pass_err:'Username/password is incorrect. Three more attempts left.',
    figcheck_loginbox_msg_user_not_exist:'There is no user registered with that Email address. Check your Email address and try again later.',
    figcheck_loginbox_msg_user_has_exist:'The account already exists. Please log in.',
    figcheck_loginbox_msg_reg_fail:'Registration failed. Try again.',
    figcheck_loginbox_msg_logout_succ:'Logout successful',
    //邮箱验证码
    figcheck_email_titile:'Security code sent by FigCheck',
    figcheck_email_content:'Your security code is {1}. The code will expire in 5 mins, please enter the code quickly for successful registration',
    //头部菜单
    figcheck_menu_self_check:'Academic self-examination',
    figcheck_menu_readme:'Instructions for use',
    figcheck_menu_pub_sub:'Pubpeer subscription',
    figcheck_menu_peer_review:'Peer review',
    figcheck_menu_api_access:'Institutional API access',

    // mcarcheck
    figcheck_pay_id_account:"Account ID",
    figcheck_pay_remain_num:"Remaining Count",
    figcheck_pay_remain_num_unit:" Times",
    figcheck_pay_not_login:"Not logged in yet",
    figcheck_pay_action:"Donation",
    figcheck_pay_money_unit_char:"￥",
    figcheck_pay_money_unit_str:"Yuan",
    figcheck_pay_order_num:"Order Number",
    figcheck_pay_type:"Payment Method",
    figcheck_pay_type_wx:"WeChat Scan",
    figcheck_pay_type_ali:"Alipay Scan",
    figcheck_pay_msg:"Message",
    // figcheck_pay_prompt_1:"1. Overtime is not counted.",
    figcheck_pay_prompt_2:"1.This payment is for special virtual goods and does not support refunds. Please fully confirm its usefulness before donating. Thank you.",
    figcheck_pay_prompt_3:"2.Please do not pay repeatedly; after the payment is completed, if the count does not increase, please log out and log in again; for other issues, please contact customer service in time.",
    figcheck_pay_prompt_4_1:"3.For invoice issuance, please contact",
    figcheck_pay_prompt_4_2:"Staff",
    figcheck_pay_prompt_4_3:".",
    figcheck_pay_qcode_title:"Scan to add customer service WeChat",
    figcheck_pay_qcode_prompt:"Customer Service WeChat",

    figcheck_pay_msg_success:'Payment Completed',
    figcheck_pay_msg_nopay:'Unpaid',

    check_result_toggle_to_list_view:"View List",
    check_result_toggle_to_chart_view:"View Bar Chart",

    article_pub_date:"Publication Date",
    article_article_type:"Document Type",
    article_cited_num:"Citation Count",
    article_keyword:"Keywords",
    article_author:"Author",
    article_click_middle_author:"Click to Expand Middle Author",
    article_journal:"Journal",
    article_unpublish:"Unpublished",
    article_cas:"CAS ",
    article_cas_postfix:"",
    article_warning:"Warning",
    article_fisrt_collect:"First Inclusion",
    article_collect_time:"Collection Time",
    article_summary:"Abstract",
    article_expand_summary:"Expand Abstract",
    article_contraction_summary:"Contraction Abstract",
    article_zh_summary:"Chinese Abstract",
    article_en_summary:"English Abstract",
    article_summary_translating:"Translating Abstract…",
    article_click_retranslate:"Incorrect translation? Click to retranslate",
    article_reason:"Reason",

    selfcheck_state_01:"Total Public Opinion",
    selfcheck_state_01_postfix:"",
    selfcheck_state_02:"Last Week",
    selfcheck_state_02_postfix:"",
    selfcheck_state_03:"This Week So Far",
    selfcheck_state_03_postfix:"",
    selfcheck_total:"Total",
    selfcheck_rewatch_default:"Journal Action",
    selfcheck_all:"All",
    selfcheck_rewatch_01:"Retraction",
    selfcheck_rewatch_02:"Erratum",
    selfcheck_rewatch_03:"Attention",
    selfcheck_pubpeer:"Pubpeer Exposure",
    selfcheck_figcheck:"Figcheck Screening",
    selfcheck_page_prompt:"Only Display the First 10,000 Entries",
    selfcheck_page_close:"This page has been closed, please contact the administrator.",

    selfcheck_search:"Search",
    selfcheck_reset:"Reset",
    selfcheck_article_num:"Article Num"

}
